:root {
  --davys-grey: rgb(87, 86, 83);
  --nickel: rgb(117, 117, 108);
  --blacc: rgba(0, 0, 0);
  --darkgray: rgb(17, 19, 20);
  --offwhite: rgb(216, 208, 207);
  --dimgray: rgb(108, 108, 108);
}

.App {
  text-align: center;
}

hr.solid {
  border-top: 3px solid #bbb;
}



.header {
  display: flex;
  width:100%;
  height: 70px;
  background:  var(--darkgray);
  color:  var(--offwhite);
  
}

#header-l {
  display: flex;
  padding-inline:20px;
  align-items: center;
  justify-content: left;
  width:35%;
}

#header-c {
  display: flex;
  align-items: top;
  justify-content: center;
  width:30%;
  height:100%;
}

#header-r {
  display: flex;
  align-items: center;
  padding-inline:20px;
  justify-content: right;
  width:35%;
}

@media (max-width: 915px) {
  #header-l {
    display: none;
  }
  
  #header-c {
    display: flex;
    align-items: top;
    justify-content: center;
    width:100%;
    height:100%;
  }
  
  #header-r {
    display: none;
  }
}



#typeWriter{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline:20px;
  padding-bottom:0px;
  margin-top: 15px;
  height:fit-content;
  background-color: var(--darkgray);
  border-radius: 6px;
  z-index: 2;

}

#links{
  position:relative;
  padding-inline:0px;
}

.inlineDiv{
  display:inline-block;
  vertical-align: middle;
  margin:20px;
}

.linkboxmenu{
  padding: 6px;
  padding-inline: max(1.2vw, 20px);
  
  float:left;
}

.linkbox{
  padding-inline: max(1.2vw, 7px);
  float: left;
}

.ascii-art {
  font-size: min(1.3vh, 0.7vw);
  font-family: monospace;
  white-space: pre-wrap;
}

#mainBody{
  width: 100%;
  height:100%;
  color: var(--offwhite);

  
}

#leftBar{
  width: 94px;
  height: 100%;
  float: left;
}

.inner {
  color: var(--offwhite);
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width: 100%;
  height:90vh;

}

.inner::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.centeredDiv {
  position: absolute;
  top: 50%; left: 50%;
  width: 92%;
  height:90%;
  transform: translate(-50%,-50%);
}

.centeredlogo {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  color: var(--darkgray);
  font-size: 30vw;
  z-index: 2;
}


#p5CanvasParent{
  width: 100vw;
  height: 92vh;
  z-index: 1;
}
#rightBar{
  width:94px;
  height: 100%;
  float: right;
}


a{
  display: inline-block;
  position: relative;
  color: var(--offwhite);
  text-decoration: none;
}

a:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--offwhite);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.caption{
  display: table-caption;
  caption-side: bottom;
}

.captionedImg{
  display: inline-table;
  vertical-align: top;
  padding: 5px;
}


#projShowcase{
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  width: 100%;
  height:70%;
}

.picList{
  clear:both;
  overflow: scroll;
}

.projList{
  display: table;
}


.captionedProject{
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  display: inline-table;
  background-clip: content-box;
  padding:10px;
  width:310px;
  height:500px;
  background-color: var(--offwhite); 
  transition:  background-color 0.5s;
}

.captionedProject:hover{
  background-color: rgb(31, 153, 153); 
  transition:  background-color 0.5s;
}

.projImg{
  display: flex;
  margin:2px;
  padding:3px;
  background-color: var(--darkgray);
  
  align-items: center;
  justify-content: center;
  width:300px;
  height:300px;
}

.projCap{
  color: var(--darkgray);
  text-align: left;
  vertical-align:top;
  margin:10px;
  height:100px;
  
}

/*burger menu stuff*/
#mobile-logo{
 display:none;
}

.menu-button-container {

  display: none;
  height: 50px;
  width: 50px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 4;
}

.menu > li {
  display:none;
  margin: 0 1rem;
  overflow: hidden;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: var(--offwhite);
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 915px) {
  #mobile-logo{
    position: absolute;
    top: 0%;
    left: 10%;
    transform: translate(-100%, 50%);
    display: flex;
   }

  .menu-button-container {
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translate(-110%, 20%);
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 85px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: var(--darkgray);
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}